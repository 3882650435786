import ArrowIcon from '@/components/icons/ArrowIcon';
import Image from '@/components/Image';

import { useState } from 'react';
import { useRouter } from 'next/router';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Link from 'next/link';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import { renderImageHandler } from '@/helpers/common/renderImage';

const HomeBannerSwiper = ({ sliders }) => {
  const { locale, push } = useRouter();
  const [swiper, set_swiper] = useState({});
  return (
    <Swiper
      key={locale}
      dir={locale === 'en' ? 'ltr' : 'rtl'}
      onInit={(e) => set_swiper(e)}
      className='banner-swiper'
      pagination={{ clickable: true }}
      modules={[Pagination]}
    >
      {sliders?.map((slider, i) => {
        const imgSrc = renderImageHandler(slider, locale);

        return (
          <SwiperSlide key={i}>
            <Link
              className='block'
              href={handleLinkByType(slider?.url, slider?.type)}
            >
              <Image
                loading='eager'
                src={imgSrc}
                className='h-[368px] w-full rounded-xl object-cover sm:h-[328px]'
                alt='Banner 1'
              />
              <div className='absolute left-0 top-0 z-50 h-full w-full rounded-xl bg-black bg-opacity-45'>
                <div className='relative top-1/2 mx-auto w-11/12 -translate-y-1/2 text-white max-sm:py-3'>
                  <p className='mb-3 flex justify-between max-sm:flex-col-reverse max-sm:gap-12 sm:mb-5 sm:items-center'>
                    <span className='font-medium max-sm:ps-3 max-sm:text-xs'>
                      {slider?.[`title-${locale}`]}
                    </span>
                    <span className='flex items-center gap-10 max-sm:justify-end max-sm:gap-5'>
                      <ArrowIcon
                        className={`${
                          locale === 'en' ? 'rotate-90' : '-rotate-90'
                        } cursor-pointer`}
                        onClick={() => swiper?.slidePrev()}
                      />
                      <ArrowIcon
                        className={`${
                          locale === 'en' ? '-rotate-90' : 'rotate-90'
                        } cursor-pointer`}
                        onClick={() => swiper?.slideNext()}
                      />
                    </span>
                  </p>
                  <p className='mb-3 text-xl font-medium max-sm:ps-3 sm:mb-6 sm:text-[34px]'>
                    {slider?.[`subtitle-${locale}`]}
                  </p>
                  <p className='text-xl font-medium max-sm:mb-6 max-sm:ps-3 sm:text-[34px]'>
                    {slider?.[`description-${locale}`]}
                  </p>
                  <button
                    onClick={() => push(handleLinkByType(slider?.url, slider?.type))}
                    className='mt-1.5 inline-block rounded-md bg-white px-7 py-2 text-sm font-medium text-primary max-sm:ms-3 max-sm:text-sm sm:mt-9 sm:py-3'
                  >
                    {slider?.[`button-${locale}`]}
                  </button>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HomeBannerSwiper;
