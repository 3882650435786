import React from 'react';

import { useRouter } from 'next/router';
import Image from '../Image';
import Link from 'next/link';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import { renderImageHandler } from '@/helpers/common/renderImage';

const StaticBanners = ({ data }) => {
  const { locale, push } = useRouter();
  const activeData = data?.value?.filter((item) => item?.active);

  return (
    activeData.length > 0 && (
      <div className={`grid gap-4 sm:grid-cols-${activeData?.length} md:my-11`}>
        {activeData?.map((banner, i) => {
          const imgSrc = renderImageHandler(banner, locale);

          return (
            <Link
              key={i}
              href={handleLinkByType(banner?.url, banner?.type)}
              className='relative block'
            >
              <Image
                loading='eager'
                src={imgSrc}
                className={`w-full rounded-xl object-cover max-sm:h-[140px] ${
                  activeData?.length > 1 ? 'h-[317px]' : 'h-[342px]'
                }`}
                alt={banner?.['title-' + locale]}
              />
              <div className='absolute start-0 top-0 z-50 flex h-full w-full flex-col justify-center rounded-xl bg-black bg-opacity-50 ps-7 text-white sm:ps-14 sm:*:mb-3'>
                <p className='text-base max-sm:mb-3 sm:text-xs sm:font-medium'>
                  {banner?.['title-' + locale]}
                </p>
                <p className='p  text-xl font-medium uppercase sm:font-semibold'>
                  {banner?.['subtitle-' + locale]}
                </p>
                <p className='p text-xl font-medium uppercase sm:font-semibold'>
                  {banner?.['description-' + locale]}
                </p>
                <button
                  onClick={() => push(handleLinkByType(banner?.url, banner?.type))}
                  className='mt-4 block w-32 rounded-md bg-white py-1.5 text-center text-sm font-medium text-primary max-sm:hidden sm:p-2'
                >
                  {banner?.['button-' + locale]}
                </button>
              </div>
            </Link>
          );
        })}
      </div>
    )
  );
};

export default StaticBanners;
